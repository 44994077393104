var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.isTraversal = exports.procedure = void 0;
exports.procedure = {
  universal: 50,
  tag: 30,
  attribute: 1,
  pseudo: 0,
  "pseudo-element": 0,
  "column-combinator": -1,
  descendant: -1,
  child: -1,
  parent: -1,
  sibling: -1,
  adjacent: -1,
  _flexibleDescendant: -1
};

function isTraversal(t) {
  return exports.procedure[t.type] < 0;
}

exports.isTraversal = isTraversal;
export default exports;