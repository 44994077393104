import * as _boolbase2 from "boolbase";

var _boolbase = "default" in _boolbase2 ? _boolbase2.default : _boolbase2;

import * as _cssWhat2 from "css-what";

var _cssWhat = "default" in _cssWhat2 ? _cssWhat2.default : _cssWhat2;

import _filters from "./filters";
import _pseudos from "./pseudos";
import _aliases from "./aliases";
import _subselects from "./subselects";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.compilePseudoSelector = exports.aliases = exports.pseudos = exports.filters = void 0;
/*
 * Pseudo selectors
 *
 * Pseudo selectors are available in three forms:
 *
 * 1. Filters are called when the selector is compiled and return a function
 *  that has to return either false, or the results of `next()`.
 * 2. Pseudos are called on execution. They have to return a boolean.
 * 3. Subselects work like filters, but have an embedded selector that will be run separately.
 *
 * Filters are great if you want to do some pre-processing, or change the call order
 * of `next()` and your code.
 * Pseudos should be used to implement simple checks.
 */

var boolbase_1 = _boolbase;
var css_what_1 = _cssWhat;
var filters_1 = _filters;
Object.defineProperty(exports, "filters", {
  enumerable: true,
  get: function () {
    return filters_1.filters;
  }
});
var pseudos_1 = _pseudos;
Object.defineProperty(exports, "pseudos", {
  enumerable: true,
  get: function () {
    return pseudos_1.pseudos;
  }
});
var aliases_1 = _aliases;
Object.defineProperty(exports, "aliases", {
  enumerable: true,
  get: function () {
    return aliases_1.aliases;
  }
});
var subselects_1 = _subselects;

function compilePseudoSelector(next, selector, options, context, compileToken) {
  var name = selector.name,
      data = selector.data;

  if (Array.isArray(data)) {
    return subselects_1.subselects[name](next, data, options, context, compileToken);
  }

  if (name in aliases_1.aliases) {
    if (data != null) {
      throw new Error("Pseudo ".concat(name, " doesn't have any arguments"));
    } // The alias has to be parsed here, to make sure options are respected.


    var alias = (0, css_what_1.parse)(aliases_1.aliases[name]);
    return subselects_1.subselects.is(next, alias, options, context, compileToken);
  }

  if (name in filters_1.filters) {
    return filters_1.filters[name](next, data, options, context);
  }

  if (name in pseudos_1.pseudos) {
    var pseudo_1 = pseudos_1.pseudos[name];
    (0, pseudos_1.verifyPseudoArgs)(pseudo_1, name, data);
    return pseudo_1 === boolbase_1.falseFunc ? boolbase_1.falseFunc : next === boolbase_1.trueFunc ? function (elem) {
      return pseudo_1(elem, options, data);
    } : function (elem) {
      return pseudo_1(elem, options, data) && next(elem);
    };
  }

  throw new Error("unmatched pseudo-class :".concat(name));
}

exports.compilePseudoSelector = compilePseudoSelector;
export default exports;