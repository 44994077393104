import * as _domutils2 from "domutils";

var _domutils = "default" in _domutils2 ? _domutils2.default : _domutils2;

import * as _boolbase2 from "boolbase";

var _boolbase = "default" in _boolbase2 ? _boolbase2.default : _boolbase2;

import _compile from "./compile";
import _subselects from "./pseudo-selectors/subselects";
import _pseudoSelectors from "./pseudo-selectors";
var exports = {};

var __createBinding = exports && exports.__createBinding || (Object.create ? function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  var desc = Object.getOwnPropertyDescriptor(m, k);

  if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
    desc = {
      enumerable: true,
      get: function () {
        return m[k];
      }
    };
  }

  Object.defineProperty(o, k2, desc);
} : function (o, m, k, k2) {
  if (k2 === undefined) k2 = k;
  o[k2] = m[k];
});

var __setModuleDefault = exports && exports.__setModuleDefault || (Object.create ? function (o, v) {
  Object.defineProperty(o, "default", {
    enumerable: true,
    value: v
  });
} : function (o, v) {
  o["default"] = v;
});

var __importStar = exports && exports.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);

  __setModuleDefault(result, mod);

  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.aliases = exports.pseudos = exports.filters = exports.is = exports.selectOne = exports.selectAll = exports.prepareContext = exports._compileToken = exports._compileUnsafe = exports.compile = void 0;

var DomUtils = __importStar(_domutils);

var boolbase_1 = _boolbase;
var compile_1 = _compile;
var subselects_1 = _subselects;

var defaultEquals = function (a, b) {
  return a === b;
};

var defaultOptions = {
  adapter: DomUtils,
  equals: defaultEquals
};

function convertOptionFormats(options) {
  var _a, _b, _c, _d;
  /*
   * We force one format of options to the other one.
   */
  // @ts-expect-error Default options may have incompatible `Node` / `ElementNode`.


  var opts = options !== null && options !== void 0 ? options : defaultOptions; // @ts-expect-error Same as above.

  (_a = opts.adapter) !== null && _a !== void 0 ? _a : opts.adapter = DomUtils; // @ts-expect-error `equals` does not exist on `Options`

  (_b = opts.equals) !== null && _b !== void 0 ? _b : opts.equals = (_d = (_c = opts.adapter) === null || _c === void 0 ? void 0 : _c.equals) !== null && _d !== void 0 ? _d : defaultEquals;
  return opts;
}

function wrapCompile(func) {
  return function addAdapter(selector, options, context) {
    var opts = convertOptionFormats(options);
    return func(selector, opts, context);
  };
}
/**
 * Compiles the query, returns a function.
 */


exports.compile = wrapCompile(compile_1.compile);
exports._compileUnsafe = wrapCompile(compile_1.compileUnsafe);
exports._compileToken = wrapCompile(compile_1.compileToken);

function getSelectorFunc(searchFunc) {
  return function select(query, elements, options) {
    var opts = convertOptionFormats(options);

    if (typeof query !== "function") {
      query = (0, compile_1.compileUnsafe)(query, opts, elements);
    }

    var filteredElements = prepareContext(elements, opts.adapter, query.shouldTestNextSiblings);
    return searchFunc(query, filteredElements, opts);
  };
}

function prepareContext(elems, adapter, shouldTestNextSiblings) {
  if (shouldTestNextSiblings === void 0) {
    shouldTestNextSiblings = false;
  }
  /*
   * Add siblings if the query requires them.
   * See https://github.com/fb55/css-select/pull/43#issuecomment-225414692
   */


  if (shouldTestNextSiblings) {
    elems = appendNextSiblings(elems, adapter);
  }

  return Array.isArray(elems) ? adapter.removeSubsets(elems) : adapter.getChildren(elems);
}

exports.prepareContext = prepareContext;

function appendNextSiblings(elem, adapter) {
  // Order matters because jQuery seems to check the children before the siblings
  var elems = Array.isArray(elem) ? elem.slice(0) : [elem];
  var elemsLength = elems.length;

  for (var i = 0; i < elemsLength; i++) {
    var nextSiblings = (0, subselects_1.getNextSiblings)(elems[i], adapter);
    elems.push.apply(elems, nextSiblings);
  }

  return elems;
}
/**
 * @template Node The generic Node type for the DOM adapter being used.
 * @template ElementNode The Node type for elements for the DOM adapter being used.
 * @param elems Elements to query. If it is an element, its children will be queried..
 * @param query can be either a CSS selector string or a compiled query function.
 * @param [options] options for querying the document.
 * @see compile for supported selector queries.
 * @returns All matching elements.
 *
 */


exports.selectAll = getSelectorFunc(function (query, elems, options) {
  return query === boolbase_1.falseFunc || !elems || elems.length === 0 ? [] : options.adapter.findAll(query, elems);
});
/**
 * @template Node The generic Node type for the DOM adapter being used.
 * @template ElementNode The Node type for elements for the DOM adapter being used.
 * @param elems Elements to query. If it is an element, its children will be queried..
 * @param query can be either a CSS selector string or a compiled query function.
 * @param [options] options for querying the document.
 * @see compile for supported selector queries.
 * @returns the first match, or null if there was no match.
 */

exports.selectOne = getSelectorFunc(function (query, elems, options) {
  return query === boolbase_1.falseFunc || !elems || elems.length === 0 ? null : options.adapter.findOne(query, elems);
});
/**
 * Tests whether or not an element is matched by query.
 *
 * @template Node The generic Node type for the DOM adapter being used.
 * @template ElementNode The Node type for elements for the DOM adapter being used.
 * @param elem The element to test if it matches the query.
 * @param query can be either a CSS selector string or a compiled query function.
 * @param [options] options for querying the document.
 * @see compile for supported selector queries.
 * @returns
 */

function is(elem, query, options) {
  var opts = convertOptionFormats(options);
  return (typeof query === "function" ? query : (0, compile_1.compile)(query, opts))(elem);
}

exports.is = is;
/**
 * Alias for selectAll(query, elems, options).
 * @see [compile] for supported selector queries.
 */

exports.default = exports.selectAll; // Export filters, pseudos and aliases to allow users to supply their own.

var pseudo_selectors_1 = _pseudoSelectors;
Object.defineProperty(exports, "filters", {
  enumerable: true,
  get: function () {
    return pseudo_selectors_1.filters;
  }
});
Object.defineProperty(exports, "pseudos", {
  enumerable: true,
  get: function () {
    return pseudo_selectors_1.pseudos;
  }
});
Object.defineProperty(exports, "aliases", {
  enumerable: true,
  get: function () {
    return pseudo_selectors_1.aliases;
  }
});
export default exports;
export const __esModule = exports.__esModule,
      aliases = exports.aliases,
      pseudos = exports.pseudos,
      filters = exports.filters,
      selectOne = exports.selectOne,
      selectAll = exports.selectAll,
      _compileToken = exports._compileToken,
      _compileUnsafe = exports._compileUnsafe,
      compile = exports.compile;
const _is = exports.is,
      _prepareContext = exports.prepareContext;
export { _is as is, _prepareContext as prepareContext };