var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.verifyPseudoArgs = exports.pseudos = void 0; // While filters are precompiled, pseudos get called when they are needed

exports.pseudos = {
  empty: function (elem, _a) {
    var adapter = _a.adapter;
    return !adapter.getChildren(elem).some(function (elem) {
      // FIXME: `getText` call is potentially expensive.
      return adapter.isTag(elem) || adapter.getText(elem) !== "";
    });
  },
  "first-child": function (elem, _a) {
    var adapter = _a.adapter,
        equals = _a.equals;
    var firstChild = adapter.getSiblings(elem).find(function (elem) {
      return adapter.isTag(elem);
    });
    return firstChild != null && equals(elem, firstChild);
  },
  "last-child": function (elem, _a) {
    var adapter = _a.adapter,
        equals = _a.equals;
    var siblings = adapter.getSiblings(elem);

    for (var i = siblings.length - 1; i >= 0; i--) {
      if (equals(elem, siblings[i])) return true;
      if (adapter.isTag(siblings[i])) break;
    }

    return false;
  },
  "first-of-type": function (elem, _a) {
    var adapter = _a.adapter,
        equals = _a.equals;
    var siblings = adapter.getSiblings(elem);
    var elemName = adapter.getName(elem);

    for (var i = 0; i < siblings.length; i++) {
      var currentSibling = siblings[i];
      if (equals(elem, currentSibling)) return true;

      if (adapter.isTag(currentSibling) && adapter.getName(currentSibling) === elemName) {
        break;
      }
    }

    return false;
  },
  "last-of-type": function (elem, _a) {
    var adapter = _a.adapter,
        equals = _a.equals;
    var siblings = adapter.getSiblings(elem);
    var elemName = adapter.getName(elem);

    for (var i = siblings.length - 1; i >= 0; i--) {
      var currentSibling = siblings[i];
      if (equals(elem, currentSibling)) return true;

      if (adapter.isTag(currentSibling) && adapter.getName(currentSibling) === elemName) {
        break;
      }
    }

    return false;
  },
  "only-of-type": function (elem, _a) {
    var adapter = _a.adapter,
        equals = _a.equals;
    var elemName = adapter.getName(elem);
    return adapter.getSiblings(elem).every(function (sibling) {
      return equals(elem, sibling) || !adapter.isTag(sibling) || adapter.getName(sibling) !== elemName;
    });
  },
  "only-child": function (elem, _a) {
    var adapter = _a.adapter,
        equals = _a.equals;
    return adapter.getSiblings(elem).every(function (sibling) {
      return equals(elem, sibling) || !adapter.isTag(sibling);
    });
  }
};

function verifyPseudoArgs(func, name, subselect) {
  if (subselect === null) {
    if (func.length > 2) {
      throw new Error("pseudo-selector :".concat(name, " requires an argument"));
    }
  } else if (func.length === 2) {
    throw new Error("pseudo-selector :".concat(name, " doesn't have any arguments"));
  }
}

exports.verifyPseudoArgs = verifyPseudoArgs;
export default exports;